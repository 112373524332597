<template>
  <div class="home">
    <header class="header clearfix">
      <div class="head-logo">
        <router-link to="/">
          <img
            src="@/assets/image/head-nav/logo.png"
            alt=""
          />
        </router-link>
      </div>

      <nav class="head-nav">
        <ul class="nav">
          <li
            class="nav-item"
            v-for="(item, index) in nav"
            :key="index"
          >
            <div @click="navLink(item.link)" >
              <div class="nav-item-btn">{{ item.name }}</div>
            </div>
            <div class="nav-option">
              <div
                class="optionList"
                @click="onClickNavList(items.link)"
                v-for="(items, index) in item.lists"
                :key="index"
              >{{items.name}}</div>
            </div>
          </li>
        </ul>

      </nav>
      <div class="nav-item-translate">
        <img
          @click="onI18nClick"
          :src="$t('headNav.img')"
          alt=""
        >
      </div>
    </header>
  </div>
</template>

<script>
import gql from 'graphql-tag';

export default {
  apollo: {
    nav: {
      query() {
        if (this.$i18n.locale === 'cn') {
          return gql`
            query {
              nav: allPrimaryLabels {
                name
                link
                lists: secondaryLabels {
                  name
                  link
                }
              }
            }
          `;
        }
        return gql`
          query {
            nav: allEnPrimaryLabels {
              name
              link
              lists: secondaryLabels {
                name
                link
              }
            }
          }
        `;
      },
    },
  },
  data() {
    return {
      nav: [],
    };
  },
  methods: {
    navLink(navLink) {
      console.log(this.$route.path);
      if (navLink !== '/about-qualifications') {
        this.$router.push({
          path: navLink,
        });
      }
    },
    onI18nClick() {
      if (this.$i18n.locale === 'en') {
        this.$i18n.locale = 'cn';
      } else if (this.$i18n.locale === 'cn') {
        this.$i18n.locale = 'en';
      }
      this.$router.replace({
        query: {
          lang: this.$i18n.locale,
        },
      });
    },
    onClickNavList(tolink) {
      this.$router.push({ path: tolink });
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.home {
  .header {
    width: 1200px;
    margin: 0 auto;
    height: 140px;
    display: flex;
    flex: 1;
    justify-content: space-around;

    .head-logo {
      width: 220px;
      line-height: 100px;

      img {
        width: 140px;
      }
    }

    .head-nav {
      width: 980px;

      .nav {
        width: 100%;
        display: flex;
        justify-content: space-around;

        .nav-item {
          position: relative;
          height: 140px;
          line-height: 140px;
          width: 140px;
          text-align: center;
          color: #333333;
          transition: all 0.6s;
          z-index: 9;

          &:hover {
            cursor: pointer;

            .nav-item-btn {
              background: #ffbf00 !important;
              border-bottom: 4px solid #555555;
            }
          }

          &:hover .nav-option {
            max-height: 1000px;
          }

          .router-link-active {
            .nav-item-btn {
              background: #ffbf00 !important;
              border-bottom: 4px solid #555555;
            }
          }

          .nav-option {
            position: relative;
            top: 0px;
            left: 0;
            z-index: 2;
            width: 100%;
            -webkit-transition: max-height 0.1s ease-in-out;
            -moz-transition: max-height 0.1s ease-in-out;
            -o-transition: max-height 0.1s ease-in-out;
            transition: max-height 1.5s ease-in-out;
            max-height: 0;
            overflow: hidden;
            color: #555;

            .optionList {
              height: 50px;
              line-height: 50px;
              background: #ffbf00;
              position: relative;

              &:hover {
                background: #e8ae03;
              }
            }
          }
        }
      }
    }
  }
  .nav-item-translate {
    position: absolute;
    right: 0px;
    top: 50px;

    &:hover {
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 1420px) {
  .home .header .head-nav {
    width: 880px;
    margin-left: -100px;
  }

  .home .header .nav-item-translate {
    right: 50px;
  }
}

@media screen and (max-width: 1200px) {
  .home .header .nav-item-translate {
    left: 1140px;
  }
}

@media screen and (min-width: 1422px) {
  .home .header .nav-item-translate {
    right: 60px;
  }
}

@media screen and (min-width: 1560px) {
  .home .header .nav-item-translate {
    right: 140px;
  }
}
</style>
